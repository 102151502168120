function Hamburger(props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 128 128" width="80px" height="80px">
      <path fill="#FFFFFF" d="M86.5 52h-45c-1.7 0-3-1.3-3-3s1.3-3 3-3h45c1.7 0 3 1.3 3 3S88.2 52 86.5 52zM86.5 67h-45c-1.7 0-3-1.3-3-3s1.3-3 3-3h45c1.7 0 3 1.3 3 3S88.2 67 86.5 67z"/>
      <g>
        <path fill="#FFFFFF" d="M86.5,82h-45c-1.7,0-3-1.3-3-3s1.3-3,3-3h45c1.7,0,3,1.3,3,3S88.2,82,86.5,82z"/>
      </g>
    </svg>
  );
}

export default Hamburger